import React, { useState, useEffect } from "react";
import './Player.css'
import musicOn from '../img/musicOn.svg'
import musicOff from '../img/musicOff.svg'

const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
      playing ? audio.play() : audio.pause();
    },
    [playing]
  );

  useEffect(() => {
    audio.volume = 0.1
    audio.loop = true
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

const Player = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <div className="player">
      <img alt="Toggle music play" onClick={toggle} src={playing ? musicOn : musicOff}></img>
    </div>
  );
};

export default Player;