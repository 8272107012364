import React from "react";
import { ParallaxProvider, ParallaxBanner } from "react-scroll-parallax";

import bg1 from "../img/1_Background.png";
import bg2 from "../img/2_Cat_God.png";
import bg3 from "../img/3_Mountains.png";
import bg4 from "../img/4_Grass_Back.png";
import bg5 from "../img/5_Grass_Front.png";
import bg6 from "../img/6_Small_Blue_Egg.png";
import bg7 from "../img/7_tree1.png";
import bg8 from "../img/8_tree2.png";
import bg9 from "../img/9_tree3.png";
import bg11 from "../img/11_Clouds.png";
import bg12 from "../img/12_Small_Chonky_White.png";
import bg13 from "../img/13_Small_Chonky_Yellow.png";
import bg14 from "../img/14_Berry_Bushes.png";
import bg16 from "../img/16_Magic_Crystals.png";
import bg17 from "../img/17_World_Tree_Village.png";
import bg18 from "../img/18_Extra_Chonky.png";

import "./index.css";

export default function Par() {
  return (
      <ParallaxProvider>
        <header>
          <ParallaxBanner
            className="banner"
            layers={[
              {
                image: bg1,
                translateY: [0, 0],
                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: bg2,
                translateY: [0, 45],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: bg3,
                translateY: [10, 30],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: bg4,
                translateY: [-4, 20],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: bg16,
                translateY: [-4, 20],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: bg17,
                translateY: [-4, 20],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: bg5,
                translateY: [0, 0],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: bg6,
                translateY: [0, 0],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: bg7,
                translateY: [0, 0],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: bg8,
                translateY: [0, 0],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: bg9,
                translateY: [0, 0],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              // {
              //   image: bg10,
              //   translateY: [-4, 20],

              //   shouldAlwaysCompleteAnimation: true,
              //   expanded: false,
              // },
              {
                image: bg11,
                translateY: [0, 0],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: bg12,
                translateY: [0, 0],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: bg13,
                translateY: [0, 0],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: bg14,
                translateY: [0, 0],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              // {
              //   image: bg15,
              //   translateY: [10, 20],

              //   shouldAlwaysCompleteAnimation: true,
              //   expanded: false,
              // },
              {
                image: bg18,
                translateY: [0, 0],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                translateY: [40, 30],
                scale: [1, 1.05, 'easeOutCubic'],
                shouldAlwaysCompleteAnimation: true,
                expanded: false,
                children: (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <h1 className='header-logo'>Chonkymon</h1>
                  </div>
                ),
              }
            ]}
          />
        </header>
      </ParallaxProvider>
  );
}