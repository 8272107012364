
import React from "react";
import Web3 from "web3";
import { connectWallet, getCurrentWalletConnected } from "../utils/interact";
import SmartContract from "../contracts/DoodleRawrs.json";

import { Button} from 'react-bootstrap';

import egg1 from '../img/Transparent_Blue_Glow.png'
import egg2 from '../img/Transparent_Orange_Glow.png'
import egg3 from '../img/Transparent_Pink_Glow.png'

import './Mint.css'

import Player from '../soundPlayer/Player'
import audio from '../img/Chonkymon.mp3'

import chonkymon from '../img/animated-chonky.gif'
import Par from "../parallax";

// import ScrollAnimation from 'react-animate-on-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'

import celt from '../img/Celt.png'
import eldor from '../img/Eldor2.png'

class Mint extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            swords: [],
            wallet: '',
            status: '',
            claimingNft: false,
            smartContract: '',
            web3: null,
            blockchain: null,
            errorMsg: '',
            feedback: '',
            totalSupply: '',
            totalMinted: '',
            typeOptions: [
                { value: 1, label: 1 },
                { value: 2, label: 2 },
                { value: 3, label: 3 },
                { value: 4, label: 4 },
                { value: 5, label: 5 },
                { value: 6, label: 6 },
                { value: 7, label: 7 },
                { value: 8, label: 8 },
                { value: 9, label: 9 },
                { value: 10, label: 10 }
            ],
            mintingAmount: 1
        };

        this.timer = null;

    }

    async connect() {
        if (window.ethereum) {
            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                const networkId = await window.ethereum.request({
                    method: "net_version",
                });
                var web3 = this.state.web3
                console.log(networkId)
                const NetworkData = await SmartContract.networks[networkId];
                console.log('test' + NetworkData)
                if (networkId === 1) {
                    const SmartContractObj = new web3.eth.Contract(
                        SmartContract.abi,
                        // NetworkData.address
                        //Rinkeby test contract
                        "0x9d00a27216363009C331939429a35C5d462ece6A"
                        //Live Contract
                        //"0x8bEa2b168fb0E5935bd251B1BccB142FEd006171"
                    );
                    await this.setState({
                        wallet: accounts[0],
                        smartContract: SmartContractObj,
                        web3: web3,
                    })
                } else {
                    this.connectFailed("Please change to main ETH net.");
                }
            } catch (err) {
                this.connectFailed("Something went wrong.");
            }
        } else {
            this.connectFailed("Please install Metamask.");
        }
    }

    claimNFTs = (_amount) => {
        this.setClaimingNft(true);
        var smartContract = this.state.smartContract;
        var wallet = this.state.wallet
        var web3 = this.state.web3
        try {
            smartContract.methods.mint(wallet, _amount).send({
                from: wallet,
                value: web3.utils.toWei((0.02 * _amount).toString(), "ether")
            }).then((receipt) => {
                this.setFeedback('Token(s) Successfully Minted!')
                this.setClaimingNft(false)
                this.getTotals()
            }).catch((err) => {
                console.log(err)
                this.setFeedback("Transaction failed")
                this.setClaimingNft(false)
            })
        } catch (err) {
            console.log(err);
            this.setFeedback("There was an error or you are on the wrong network.")
            this.setClaimingNft(false)
        }
    };

    claimFreeNFTs = (_amount) => {
        this.setClaimingNft(true);
        var smartContract = this.state.smartContract;
        var wallet = this.state.wallet
        var web3 = this.state.web3
        try {
            smartContract.methods.mintFree(wallet, _amount).send({
                from: wallet,
                value: web3.utils.toWei((0 * _amount).toString(), "ether")
            }).then((receipt) => {
                this.setFeedback('Token(s) Successfully Minted!')
                this.setClaimingNft(false)
                this.getTotals()
            }).catch((err) => {
                console.log(err)
                this.setFeedback("Transaction failed")
                this.setClaimingNft(false)
            })
        } catch (err) {
            console.log(err);
            this.setFeedback("There was an error or you are on the wrong network.")
            this.setClaimingNft(false)
        }
    };

    getTotals(){
        this.state.smartContract.methods.totalSupply().call().then((data) => {
            this.setState({ totalMinted: data })
        }).catch((err) => {
            console.log(err)
        })

        this.state.smartContract.methods.maxSupply().call().then((data) => {
            this.setState({ totalSupply: data })
        }).catch((err) => {
            console.log(err)
        })
    }

    async componentDidMount() {
        const { address, status } = await getCurrentWalletConnected();
        this.setWallet(address)
        this.setStatus(status);

        console.log(this.state.smartContract)

        await this.addWalletListener();
        if (this.state.wallet !== '') {
            await this.setState({ web3: new Web3(window.ethereum) });
            await this.connect()

            try {
                this.getTotals()
            } catch (e) {
                this.setFeedback('You are on the wrong network! Please connect to ETH mainnet')
            }
        }

        // const tween = KUTE.fromTo(
        //     '#rectangle',
        //     {path: "#rectangle"},
        //     {path: "#star"},
        //     {repeat: 999, duration: 3000, yoyo: true}
        // )

        // tween.start()
    }

    connectFailed(errorMsg) {
        this.setState({ errorMsg })
    }

    setWallet(wallet) {
        this.setState({
            wallet
        })
    }

    setStatus(status) {
        this.setState({
            status
        })
    }

    setFeedback(feedback) {
        this.setState({
            feedback
        })
    }

    setClaimingNft(claimingNft) {
        this.setState({
            claimingNft
        })
    }

    walletOfOwner() {
        var smartContract = this.state.smartContract
        var wallet = this.state.wallet
        var web3 = this.state.web3
        try {
            console.log(smartContract.methods)
            smartContract.methods.walletOfOwner(wallet).call().then((data) => {
                console.log(data)
            }).catch((err) => {
                console.log(err)
            })
        } catch (err) {
            console.log(err);
        }
    }

    connectWalletPressed = async () => { //TODO: implement
        const walletResponse = await connectWallet();
        await this.setStatus(walletResponse.status);
        await this.setWallet(walletResponse.address);
        if (this.state.wallet !== '') {
            await this.setState({ web3: new Web3(window.ethereum) });
            await this.connect()

            try{
                this.state.smartContract.methods.totalSupply().call().then((data) => {
                    this.setState({
                        totalMinted: data
                    })
                }).catch((err) => {
                    console.log(err)
                })
    
                this.state.smartContract.methods.maxSupply().call().then((data) => {
                    this.setState({
                        totalSupply: data
                    })
                }).catch((err) => {
                    console.log(err)
                })
            } catch(e){

            }
        }
    };

    async addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length) {
                    this.setWallet(accounts[0]);
                    this.setStatus("👆🏽 Write a message in the text-field above.");
                } else {
                    this.setWallet("");
                    this.setStatus("🦊 Connect to Metamask using the top right button.");
                }
            });
            window.ethereum.on("chainChanged", () => {
                window.location.reload();
            });
        } else {
            this.setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={`https://metamask.io/download.html`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
                    </a>
                </p>
            );
        }
    }

    async handleSelect(options, names) {
        await this.setState({
            mintingAmount: options.value
        })
    }


    render() {
        const aboutText1 = `Chonkymon are a collection of 1,000 randomly generated NFT chonky monsters. These mysterious creatures were sealed away in eggs long ago and hidden inside the World Tree. A magical door has opened to the World Tree, waiting for each to be bonded with a friend.`
        return (
            <div className="main">
                <Player url={audio} className="player"></Player>
                <Par></Par>
                <div className="about">
                    <img className='animated-left' src={chonkymon}></img>
                    <div className="container">
                        <h1>Story of Chonkymon</h1>
                        <h3>{aboutText1}</h3>
                        <div className="about-circles">
                            <div className="about-circle" style={{backgroundImage: `url(${egg1})`, backgroundPosition: "center"}}></div>
                            <div className="about-circle" style={{backgroundImage: `url(${egg2})`, backgroundPosition: "center"}}></div>
                            <div className="about-circle" style={{backgroundImage: `url(${egg3})`, backgroundPosition: "center"}}></div>
                        </div>
                    </div>
                </div>
                <div data-bueno-mint="krfmElHq9Zhnm6fKPoM1G" id="bueno"></div>
                <h1 className='team-header'>Creators</h1>
                <div className='overview' id='team'>
                {/* <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'> */}
                <div className='overviewText team'>
                    <img className='team-img' src={celt}/>
                    <h1>Celt</h1>
                    <p className="listItem"><b>Artist & Creator</b></p>
                    <p className="listItem">I joined the NFT space in Feb 2021 and have been creating many different types of digital art in the form of NFTs. I love creating anything that can bring happiness into people’s lives. I look forward to building many great things in this space, as well as a welcoming community for everyone.</p>
                    {/* <p className="listItem">Previous project - <a className='team-link' href='https://twitter.com/FinalTcg'>EthRangers </a></p> */}
                    <a className='social' href="https://twitter.com/Celt_NFT"><FontAwesomeIcon icon={faTwitter} size="2x" /></a>
                </div>
                {/* </ScrollAnimation>
                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'> */}
                <div className='overviewText team'>
                    <img className='team-img' src={eldor}/>
                    <h1>Eldor</h1>
                    <p className='listItem'><b>Solidity & Web Developer</b></p>
                    <p className='listItem'>I am a Software Engineer who likes to build games and websites. I also enjoy making art and creating visual experiences.
                    I spend my free time contributing to ETH projects, and making Discord bots.</p>
                    <a className='social' href="https://twitter.com/eldor4747"><FontAwesomeIcon icon={faTwitter} size="2x" /></a>
                </div>
                {/* </ScrollAnimation> */}
            </div>
            <div className='footer'>
                <div className='socialContainer'>
                    <a className='social' href="https://twitter.com/chonkymonnft"><FontAwesomeIcon icon={faTwitter} size="2x" /></a>
                </div>
            </div>
            </div>
        );
    }
};

export default Mint;
