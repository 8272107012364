import './App.css';
import Mint from './mint/Mint'
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Navbar, Container, Offcanvas, Nav} from 'react-bootstrap';
import "animate.css/animate.min.css";

import logo from "./img/logo-01.svg";
import wallet from "./img/wallet.png";

import ScrollAnimation from 'react-animate-on-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'

export default function App() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    document.getElementById('bueno').scrollIntoView();
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get("code")
    if(code) {
      // open offcanvas
      handleShow()
    }
  });

  
  return (
    <Router>
      <div>
      <Navbar bg="light" expand={false}>
        <Container fluid>
          <Navbar.Brand href="/"><img
            src={logo}
            width="70"
            height="70"
            className="d-inline-block align-top"
            alt="Chonkymon logo"
          /></Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link className='social' href="https://twitter.com/chonkymonnft"><FontAwesomeIcon icon={faTwitter} size="2x" /></Nav.Link>
          </Nav>
          <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow} >
          <img
            src={wallet}
            width="70"
            height="70"
            alt="Wallet Icon"
          />
          </Navbar.Toggle>
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            show={show}
            onHide={handleClose}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">Chonkyverse</Offcanvas.Title>
            </Offcanvas.Header>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/">
            <Mint />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}